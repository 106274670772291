


































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";
import { GetPlans, PlanItem } from "@/integration/get-plans";
import { SetPlan } from "@/integration/set-plans";

export default mixins(MyMixin).extend({
  name: "Plans",
  mounted() {
    this.getPlan();
    this.trackMyPageView();
  },
  metaInfo: {
    title: metadata.Plans.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.Plans.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  computed: {
    types() {
      return types;
    },
  },

  data: () => ({
    loader: false,
    loading: true,
    skeltoneLoad: true,
    item: {} as { id?: number; name?: string; features?: Array<any> },
  }),

  methods: {
    backChoosePlan() {
      this.$router.push({ name: "choose-plans" });
    },
    getPlan() {
      GetPlans.get()
        .then(({ data }) => {
          this.item = data.data.find(
            (item: { id: string; name: any; features: { value: any }[] }) => {
              if (parseInt(item.id) === parseInt(this.$route.params.plan)) {
                return {
                  name: item.name,
                  features: item.features,
                  price: item.features[0].value,
                  id: item.id,
                };
              }
            }
          );
          if (!this.item) {
            this.$router.push({ name: "leads-thank-you" });
          }
        })
        .catch(() => {
          this.$toast.error("something went wrong please try again!");
        })
        .finally(() => ((this.loading = false), (this.skeltoneLoad = false)));
    },

    submit(item: PlanItem) {
      this.loader = true;
      const itemName = item.name || "N/A";
      this.logGAEvent("Plan", itemName, (this.$route.name as string) + " Page");
      SetPlan.send({
        plan: itemName,
      })
        .then(() => {
          this.$router.push({ name: "leads-thank-you" });
        })
        .catch((err: any) => {
          this.$toast.error("Something went wrong, please try again!");
        })
        .finally(() => (this.loader = false));
    },
  },
});
