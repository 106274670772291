import { Request, Map } from '@/services/http/request';

const request = new Request(process.env.VUE_APP_BACKEND_PORTAL);


export interface FormEntry {
    plan: String;
}

export class SetPlan {
    static uri: string = "/user/plans";
    static send(data: FormEntry) {
        const userToken = localStorage.getItem('userToken') ?? '';
        return request.withCredentials(userToken).post(SetPlan.uri, data);
    }
}
